<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :rules="rules"
      :editTitle="'摊位'"
      formatterColor
    >
      <div class="search" slot="tool">
        <el-input
          placeholder="按摊位编号查询"
          v-model="searchQuery.stallNo"
          class="searchInput"
        >
          <template slot="prepend">摊位编号</template>
        </el-input>

        <!-- <div class="el-input-group__prepend">摊位类型</div> -->
        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">摊位类型</div>
          <el-select
            clearable
            v-model="searchQuery.stallTypeId"
            placeholder="按摊位类型查询"
          >
            <el-option
              v-for="item in businessTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 
        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">秤具状态</div>
          <el-select
            clearable
            v-model="searchQuery.screenStatus"
            placeholder="根据秤具状态"
          >
            <el-option
              v-for="item in status"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <el-input
          placeholder="按摊位编号查询"
          class="searchInput"
          v-model="searchVal"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchQuery.name = searchVal"
          ></el-button>
        </el-input> -->
      </div>
    </app-list>
    <el-dialog
      :visible.sync="qrCodeVisible"
      center
      :close-on-click-modal="false"
      destroy-on-close
    >
      <div class="qr-code-wrap">
        <vue-qr
          ref="qrCode"
          :text="downloadData.url"
          :margin="0"
          colorDark="#333"
          colorLight="#fff"
          :logoSrc="downloadData.imagePath"
          :size="200"
          :logoScale="0.2"
        ></vue-qr>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="qrCodeVisible = false">取 消</el-button>

        <el-button type="primary" @click="dowQrcode">下 载</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";
import vueQr from "vue-qr";
export default {
  components: {
    vueQr,
  },
  data() {
    let self = this;
    return {
      remote: api.common,
      apiName: "/market/stall",
      paymentUrl: "",
      searchVal: "",
      jobList: [],
      businessTypeList: [],
      searchQuery: { name: "" },
      downloadData: {
        url: "",
        imagePath: require("../../assets/logo.png"),
      },
      rules: {
        //传给APPlist的表单验证
        stallNo: [{ required: true, message: "请输入摊位号", trigger: ["blur","change"] }],
        stallPosition: [
          { required: true, message: "请输入摊位位置", trigger: ["blur","change"] },
        ],
        stallFloor: [
          { required: true, message: "请输入摊位楼层", trigger: ["blur","change"] },
        ],
        stallArea: [
          {
            required: true,
            validator: (relu, value, callback) => {
              if (!value) {
                callback(new Error("面积不能为空"));
              } else if (!/^\d+$/.test(value)) {
                callback(new Error("请填写阿拉伯数"));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
        stallRent: [
          {
            required: true,
            validator: (relu, value, callback) => {
              if (!value) {
                callback(new Error("请输入租金"));
              } else if (!/^\d+$/.test(value)) {
                callback(new Error("请填写阿拉伯数字"));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
        stallTypeId: [
          { required: true, message: "请选择类别", trigger: ["blur","change"] },
        ],
        marketWeighingToolId: [
          { required: true, message: "请选择称具编号", trigger: ["blur","change"] },
        ],
      },
      props: [
        {
          label: "摊位编号",
          prop: "stallNo",
          align: "center",
        },

        {
          label: "摊位位置",
          prop: "stallPosition",
          align: "center",
        },
        {
          label: "摊位楼层",
          prop: "stallFloor",
          align: "center",
        },
        {
          label: "摊位面积",
          prop: "stallArea",
          align: "center",
        },
        {
          label: "摊位租金",
          prop: "stallRent",
          align: "center",
        },
        {
          label: "经营类别",
          prop: "stallTypeName",
          align: "center",
        },
        {
          label: "秤具编号",
          prop: "marketWeighingToolNumber",
          align: "center",
        },
        {
          label: "商户屏状态",
          prop: "screenStatus",
          align: "center",
          type: "tag",
          formatter: function (row) {
            const statusMap = { 0: "离线", 1: "在线" };
            return statusMap[row.screenStatus];
          },
        },
        {
          label: "二维码",
          prop: "qrcode",
          align: "center",
          type: "operationLink",
          formatter: function (row) {
            const buttonList = [
              {
                show: true,
                text: "查看",
                data: row,
                type: "primary",
                handler: function () {
                  self.viewQrcode(row);
                },
              },
            ];
            return buttonList;
          },
        },
      ],
      formProps: [
        {
          label: "摊位编号",
          prop: "stallNo",
          type: "input",
        },

        {
          label: "摊位位置",
          prop: "stallPosition",
          type: "input",
        },

        {
          label: "摊位楼层",
          prop: "stallFloor",
          type: "input",
        },
        {
          label: "摊位面积",
          prop: "stallArea",
          type: "input",
        },
        {
          label: "摊位租金",
          prop: "stallRent",
          type: "input",
        },

        {
          label: "经营类别",
          prop: "stallTypeId",
          type: "select",
          selectData: [],
        },
        {
          label: "秤具编号",
          prop: "marketWeighingToolId",
          type: "select",
          selectData: [],
        },
      ],
      status: [
        { label: "离线", value: "0" },
        { label: "在线", value: "1" },
      ],
      qrCodeVisible: false,
    };
  },
  created() {
    this.getJobList();
    this.getWeighingToolList();
    this.getAllBusinessTypeList();
    this.getConfig();
  },
  methods: {
    viewQrcode(data) {
      // let url = this.paymentUrl + encodeURIComponent("ZR77");
      let url = this.paymentUrl + encodeURIComponent(data.stallNo);
      this.downloadData.url = url;
      this.qrCodeVisible = true;
      console.log("插卡看", data);
    },
    dowQrcode() {
      const qrSrc = this.$refs.qrCode.$el.src;
      let a = document.createElement("a");
      // 下载图名字
      a.download = "二维码";
      //url
      a.href = qrSrc;
      //合成函数，执行下载
      a.dispatchEvent(new MouseEvent("click"));
    },
    async getJobList() {
      await api.businessType.getAllList().then((res) => {
        let arr = res.data;
        arr.forEach((element) => {
          this.formProps[5].selectData.push({
            label: element.name,
            value: element.id,
          });
        });
      });
    },
    async getWeighingToolList() {
      await api.businessType.getAllWeighingTool().then((res) => {
        let arr = res.data;
        arr.forEach((element) => {
          this.formProps[6].selectData.push({
            label: element.number,
            value: element.id,
          });
        });
      });
    },
    async getAllBusinessTypeList() {
      await api.businessType.getAllList().then((res) => {
        this.businessTypeList = res.data;
      });
    },
    async getConfig() {
      await api.common.getConfig().then((res) => {
        this.paymentUrl = res.data.myPayUrl;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.qr-code-wrap {
  display: flex;
  justify-content: center;
}
</style>
